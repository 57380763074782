import dannaAvatar from "./assets/danna-avatar.png";
import juanPabloAvatar from "./assets/juan-pablo-avatar.png";
import nicoleAvatar from "./assets/nicole-avatar.png";
import maxiAvatar from "./assets/maxi-avatar.png";

export const itemsList = [
  {
    description: "Identificar tus emociones",
  },
  {
    description: "Potenciar tus habilidades de liderazgo",
  },
  {
    description: "Emprender nuevas oportunidades laborales",
  },
  {
    description: "Obtener mejores resultados en tu trabajo",
  },
  {
    description: "Desarrollar hábitos saludables",
  },
  {
    description: "Impulsar tu crecimiento personal",
  },
  {
    description: "Desafiar tus creencias limitantes",
  },
  {
    description: "Adquirir herramientas de coaching para tu vida profesional",
  },
];

export const listadoCursos = [
  {
    id: 46,
    title: "Especialista en coaching educativo",
    description: "Desarrolla habilidades para enseñar de forma didáctica",
  },
  {
    id: 72,
    title: "Coaching Vocacional",
    description: "Fórmate para impulsar futuros profesionales",
  },
  {
    id: 181,
    title: "Coaching Deportivo",
    description: "Sé el motivador que impulsa a deportistas",
  },
  {
    id: 45,
    title: "Coaching Ejecutivo",
    description: "Aplica herramientas de coaching en organizaciones",
  },
  {
    id: 174,
    title: "Coaching para equipos de trabajo",
    description:
      "Construye equipos que trabajan con fluidez y haz crecer la empresa",
  },
  {
    id: 176,
    title: "Liderazgo para mandos medios",
    description: "Potencia tu rol de líder y logra equipos eficientes",
  },
  {
    id: 120,
    title: "Neuro Oratoria",
    description: "Sé el orador que convence, impulsa y motiva a otros",
  },
  {
    id: 119,
    title: "Metodologías Ágiles",
    description: "Aprende métodos y herramientas de trabajo innovadoras",
  },
  {
    id: 179,
    title: "Técnicas de estudio para futuros profesionales",
    description: "Descubre métodos de estudio para agilizar tu aprendizaje",
  },
  {
    id: 184,
    title: "¿Cómo vender en redes sociales?",
    description:
      "Descubre cómo posicionar tu marca y generar confianza en tu público objetivo",
  },
  {
    id: 95,
    title: "Mindfulness: del estrés al bienestar",
    description: "Vive en plenitud tomando consciencia en el aquí y ahora",
  },
  {
    id: 169,
    title: "Inteligencia Emocional",
    description: "Aprende a comprender tus emociones ante situaciones adversas",
  },
];

export const selectCursos = [
  {
    value: 46,
    label: "Especialista en coaching educativo",
  },
  {
    value: 72,
    label: "Coaching Vocacional",
  },
  {
    value: 181,
    label: "Coaching Deportivo",
  },
  {
    value: 45,
    label: "Coaching Ejecutivo",
  },
  {
    value: 174,
    label: "Coaching para equipos de trabajo",
  },
  {
    value: 176,
    label: "Liderazgo para mandos medios",
  },
  {
    value: 120,
    label: "Neuro Oratoria",
  },
  {
    value: 119,
    label: "Metodologías Ágiles",
  },
  {
    value: 179,
    label: "Técnicas de estudio para futuros profesionales",
  },
  {
    value: 184,
    label: "¿Cómo vender en redes sociales?",
  },
  {
    value: 95,
    label: "Mindfulness: del estrés al bienestar",
  },
  {
    value: 169,
    label: "Inteligencia Emocional",
  },
  {
    value: 21,
    label: "Otros",
  },
];

export const testimoniosAlumnos = [
  {
    img: dannaAvatar,
    name: "Danna Urquijo",
    text: "Muy didácticas las clases, y el profe que lo dicta se hace entender muy bien, lo recomiendo al 100% y más que agradecida por tanto aprendizaje 💜",
  },
  {
    img: nicoleAvatar,
    name: "Nicole Budman",
    text: "Excelente escuela, los formadores son increíbles. Durante toda la cursada me sentí acompañada y pude aprender muchas herramientas para mi vida profesional y personal ¡Gracias totales desarrollo educativo! 🙏    ",
  },
  {
    img: juanPabloAvatar,
    name: "Juan Pablo Moris",
    text: "Una experiencia única, donde pude conocerme a fondo y enfrentar mis limitaciones. Los formadores son excelentes y el contenido muy enriquecedor, estoy más que agradecido.",
  },
  {
    img: maxiAvatar,
    name: "Maximiliano Bogado",
    text: "¡Excelente contenido! Las clases son muy didácticas y el apoyo de parte del cuerpo docente fué un plus genial. Brindan herramientas tanto para el desarrollo personal como para el desarrollo profesional y en mi opinión son muy útiles. Agradecido de haber participado en esta experiencia 😄",
  },
];

export const starArray = [1, 2, 3, 4, 5];

export const selectCountry = [
  {
    value: 1,
    label: "Argentina",
  },
  {
    value: 4,
    label: "Chile",
  },
  {
    value: 3,
    label: "Peru",
  },
  {
    value: 6,
    label: "Colombia",
  },
  {
    value: 10,
    label: "Uruguay",
  },
  {
    value: 8,
    label: "Ecuador",
  },
  {
    value: 2,
    label: "México",
  },
  {
    value: 25,
    label: "Otro",
  }
];
